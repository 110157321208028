import $ from 'jquery';
import '@/scripts/helpers/polyfills';
import 'slick-carousel/slick/slick';
import lazyframe from 'lazyframe';
import { addedLazyLoadImages } from '@/scripts/helpers/lazyload';
import { bindJQuery } from '@/scripts/helpers/library';
import { BREAKPOINTS, CLASSES } from '@/scripts/helpers/constants';
import { accordion } from '../modules/containers/accordion/index';
import { detectBrowser } from '@/scripts/helpers/detectBrowser';

const { TABLET, DESKTOP } = BREAKPOINTS;
const { IS_OPEN } = CLASSES;
const transitionTime = 170;

const compareTabletGradient = (scrollBlockName, gradientName, innerBlockName) => {
  const scrollBlock = $(scrollBlockName);
  const gradient = $(gradientName);
  const innerBlock = $(innerBlockName);

  if (!scrollBlock.length || !gradient.length || !innerBlock.length) {
    return;
  }

  scrollBlock.scroll(function () {
    const position = $(this).scrollLeft();

    if (position + scrollBlock.width() > innerBlock.width() - 10) {
      gradient.addClass('is-hidden');
    } else {
      gradient.removeClass('is-hidden');
    }
  });
};

const showMore = () => {
  if ($('.js-show-more').length) {
    $('.js-show-more').on('click', function () {
      $(this).text($(this).text() === 'Show more' ? 'Show less' : 'Show more');
      $(this).parent().toggleClass(IS_OPEN);
      $(this).parent().prev().toggleClass(IS_OPEN);
    });
  }
};

const slickSliderAboutUs = () => {
  $('.js-about-us-slider')
    .not('.slick-initialized')
    .slick({
      responsive: [
        {
          breakpoint: 9999,
          settings: 'unslick',
        },
        {
          breakpoint: DESKTOP,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            draggable: true,
            focusOnSelect: true,
            dots: true,
          },
        },
        {
          breakpoint: TABLET,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            draggable: true,
            focusOnSelect: true,
            dots: true,
          },
        },
      ],
    });
};

const slickSliderHowTo = () => {
  $('.js-how-to-slider-nav')
    .not('.slick-initialized')
    .slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      focusOnSelect: true,
      variableWidth: true,
      asNavFor: '.js-how-to-slider-body',
      responsive: [
        {
          breakpoint: DESKTOP,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: true,
          },
        },
        {
          breakpoint: TABLET,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          },
        },
      ],
    });

  $('.js-how-to-slider-body')
    .not('.slick-initialized')
    .slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      asNavFor: '.js-how-to-slider-nav',
      fade: true,
      responsive: [
        {
          breakpoint: TABLET,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            fade: false,
          },
        },
      ],
    });
};

$(document).ready(() => {
  addedLazyLoadImages();
  accordion();
  bindJQuery();
  showMore();
  detectBrowser();

  $('.js-how-to-slider-body').on('beforeChange', () => {
    $('.js-read-more').text('Read More');
    $('.js-read-more').parent().removeClass(IS_OPEN);
    $('.js-read-more').parent().prev().removeClass(IS_OPEN);
  });

  $(document).on('click', (e) => {
    const target = $(e.target);

    if (target.hasClass('js-read-more')) {
      target.text(target.text() === 'Read More' ? 'Read Less' : 'Read More');
      target.parent().toggleClass(IS_OPEN);
      target.parent().prev().toggleClass(IS_OPEN);
    }
  });

  compareTabletGradient('.section-comparison__wrapper', '.section-comparison__gradient', '.section-comparison__table');

  const elements = $('.video-player__lazyframe');

  lazyframe(elements, {
    onAppend: iframe => {
      $(iframe).attr('allow', 'autoplay');
    },
  });

  $('.section-comparison__head').on('click', function () {
    const indexCurrentRow = $(this).parent().index();
    const comparisonRows = $('.section-comparison__row');

    if (!$(this).parent().hasClass(IS_OPEN)) {
      comparisonRows.each(row => {
        if ($(row).index() < indexCurrentRow) {
          $(row).addClass('colored-background');
        }
      });

      $('.section-comparison__head').parent().removeClass(IS_OPEN);
      $('.section-comparison__head').next().slideUp(transitionTime);
      $(this).parent().addClass(IS_OPEN);
      $(this).next().slideDown(transitionTime);
    } else {
      comparisonRows.removeClass('colored-background');
      $(this).parent().removeClass(IS_OPEN);
      $(this).next().slideUp(transitionTime);
    }
  });

  slickSliderAboutUs();
  slickSliderHowTo();

  $(window).on('resize', () => {
    slickSliderAboutUs();
    slickSliderHowTo();
  });
});
